import React from 'react';
import './Homepage.scss';
import Navbar from '../navbar/navbar';
import Lineup from '../lineup/Lineup';
import Infocards from '../infocards/Infocards';
import Hero from '../hero/Hero';
import Previous from '../previous/Previous';
import Schedule from '../schedule/Schedule';
import Tips from '../tips/Tips';
import Footer from '../footer/Footer';

// Define the links for the Navbar
const links = [
  { name: 'TICKETS', link: '/tickets' },
  { name: 'RULES', link: '/rules' },
];

function Homepage() {
  return (
    <div className="App">
      <Navbar
        title="OTTERDANCE 5"
        img="https://otterdam.org/images/202404-stichting-otterdam-logo-final-transparent.png"
        links={links}
        backgroundColor="var(--navy-blue)"
        textColor="var(--white)"
        titleLink="/"
      />
      <Hero />
      <div className="whatwherewho-homepage">
        <Infocards />
      </div>
      <div className="lineup-homepage">
        <h2>LINEUP</h2>
        <Lineup />
      </div>
      <div className="schedule-homepage">
        <h2>TIME SCHEDULE AND ABOUT THE DJS</h2>
        <Schedule />
      </div>
      <div className="previous-homepage">
        <h2>PREVIOUS OTTERDANCES</h2>
        <Previous />
      </div>
      <div className="tips-homepage">
        <h2>TIPS</h2>
        <Tips />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Homepage;
