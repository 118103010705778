import React from 'react';
import './navbar.scss';

interface Link {
  name: string;
  link: string;
}

interface NavbarProps {
  title: string;
  img: string;
  links: Link[];
  backgroundColor: string;
  textColor: string;
  titleLink: string; // Add a new prop for the title link
}

/**
 * Navbar Component
 *
 * Renders a navbar with a logo, title, and links.
 *
 * @param {string} title - The title displayed next to the logo in the navbar.
 * @param {string} img - The URL of the image used as the logo.
 * @param {Link[]} links - An array of objects with the name and link for each link button.
 * @param {string} backgroundColor - The background color for the navbar.
 * @param {string} textColor - The text color for the navbar.
 * @param {string} titleLink - The URL the title should link to.
 *
 * @returns {ReactElement} The Navbar component.
 */
const Navbar: React.FC<NavbarProps> = ({
  title,
  img,
  links,
  backgroundColor,
  textColor,
  titleLink, // Destructure the new prop
}) => {
  return (
    <div
      className="navbar"
      style={
        {
          '--background-color': backgroundColor,
          '--text-color': textColor,
        } as React.CSSProperties
      }
    >
      {/* logo and title */}
      <div className="navbar__branding">
        <img className="navbar__logo" src={img} alt="logo" />
        <h1>
          <a href={titleLink} style={{ color: 'var(--text-color)' }}>
            {title}
          </a>
        </h1>
      </div>
      {/* links  */}
      <div className="navbar__links">
        {links.map((link, index) => (
          <a key={index} href={link.link}>
            {link.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
