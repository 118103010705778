//TODO: still need to add the event id
import './Tickets.scss';
import { Pretix } from '@otterdam/otterdam-components';
import Navbar from '../navbar/navbar';
import Footer from '../footer/Footer';

// Define the links for the Navbar
const links = [
  { name: 'TICKETS', link: '/tickets' },
  { name: 'RULES', link: '/rules' },
];

const Tickets = () => {
  return (
    <>
      <Navbar
        title="OTTERDANCE 5"
        img="https://otterdam.org/images/202404-stichting-otterdam-logo-final-transparent.png"
        links={links}
        backgroundColor="var(--navy-blue)"
        textColor="var(--white)"
        titleLink="/"
      />
      <div className="tickets">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            color: 'var(--white)',
          }}
        >
          <Pretix eventId="amsterdance-2" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Tickets;
