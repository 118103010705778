import './Rules.scss';
import HarmonicaItem from './harmonicalItem';
import Navbar from '../navbar/navbar';
import Footer from '../footer/Footer';

const links = [
  { name: 'TICKETS', link: '/tickets' },
  { name: 'RULES', link: '/rules' },
];

const Aditional = () => {
  return (
    <>
      <div className="rules">
        <Navbar
          title="OTTERDANCE 5"
          img="https://otterdam.org/images/202404-stichting-otterdam-logo-final-transparent.png"
          links={links}
          backgroundColor="var(--navy-blue)"
          textColor="var(--white)"
          titleLink="/"
        />
        <section id="additionalInfo" className="content-section">
          <div className="aditional">
            <div className="aditionalHarmonica">
              <HarmonicaItem
                title="General"
                text={[
                  'You must be at least 18 years old to attend.',
                  'In all matters, decisions by the organisers are final.',
                  'Violation of any rule could result in immediate removal from the event without a refund.',
                  'Pictures and videos will be taken during the event. By attending you waive all rights to pictures taken by others that might include you.',
                ]}
              />
              <HarmonicaItem
                title="Costume rules"
                text={[
                  'No costume parts that contain real animal fur.',
                  'No glitter or body paint. These are likely to damage others’ costumes.',
                  'Keep costumes at “16” (CAT.2 / PG-13) level or below. We realise that this is a judgment call: We follow Eurofurence’s guidelines (see “Clothing and Decency”). Pup masks, rubber/vinyl/lycra, and harnesses are allowed as long as they don’t expose or accentuate genitalia or buttocks. Otterdance isn’t a fetish event: Ask ahead of time if your costume is “on the edge”.',
                  'Entry to the “Headless Lounge” is for fursuiters only (with up to one assistant).',
                  'Camera/recording devices may not be used in the “Headless Lounge”.',
                ]}
              />
              <HarmonicaItem
                title="Touch rules"
                text={[
                  'Some participants will be wearing a choice of wristbands to show their attitude toward touch.',
                  'Red: Do not touch at all. Do not ask to touch.',
                  'Yellow: Ask and get an explicit “yes” before touching. If you don’t get a “yes”, do not ask again during the event.',
                  'Green: You may touch without asking, but must stop immediately if told.',
                  'If an attendee isn’t wearing a wristband, follow the rules for “Yellow” (ask and get consent before touching).',
                  'Touch includes all touch: hugs, petting, handshakes, everything. Failure to respect another’s wristband colour is cause for ejection.',
                  'Costumes are not consent.',
                  'Attendees may change their wristband colour if their mood changes. Always follow the rules for the current colour.',
                ]}
              />
              <HarmonicaItem
                title="Behaviour rules"
                text={[
                  'Keep displays of affection at “16” (CAT.2 / PG-13) level or below.',
                  'Give costumers lots of room. They might have limited vision and hard-to-control parts (such as tails).',
                  'Don’t approach or dance near costumers while carrying food or drink. Put it down first.',
                  'Don’t dance “wild” or lift anyone else off the ground.',
                ]}
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Aditional;
